import React, { forwardRef } from 'react';

import './contact.scss'
interface ContactProps {

}

const Contact = forwardRef<HTMLElement, ContactProps>((props: ContactProps, ref: React.ForwardedRef<HTMLElement>) => {
  return (
    <section ref={ref} className="component-contact">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Contact</h2>
          <p>
            A London based technical provider. Get in touch to see how we can transform your business.
          </p>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-8 d-flex align-items-stretch">
            <div className="info">
              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>contact@bedican.co.uk</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+44(0) 7980214838</p>
              </div>

              {/* <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>London, UK</p>
              </div>
              <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d59721.836709956195!2d-0.08149004112346292!3d51.51157298538048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1711625484791!5m2!1sen!2suk" width="600" height="450" style={{border:0, width:'100%', height:'290px'}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
             */}
            </div>
          </div>
        </div>

      </div>
    </section>
  );
});

export default Contact;